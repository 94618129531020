import React, { Fragment } from "react";

const Nav = (props) => {
  return (
    <Fragment>
      <nav className="bg-secondary pt-2 nav-border border-primary flex flex-row place-content-between">
        <div className="container mx-auto pl-3 pt-3 pb-3 md:pl-auto text-white text-4xl font-sans font-semibold tracking-wider">
          Ki-az-ügyi App <span className="text-xs font-normal tracking-normal">v{props.version}</span>
        </div>
      </nav>
    </Fragment>
  );
};

export default Nav;
