import React, { Fragment } from "react";

const Nav = (props) => {

  const person = () => {
    if ("next" in props && props.next !== "") {
      return <Fragment>
        1. {props.name}<br />
        2. {props.next}
      </Fragment>
    } else {
      return props.name
    }

  }

  return (
    <Fragment>
      <h3 className="text-2xl md:text-3xl text-primary mt-5">
        {props.title}
      </h3>
      <p className="text-2xl md:text-3xl">
        {person()}
      </p>
    </Fragment>
  );
};

export default Nav;
