import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faArrowLeft,
  faArrowRight,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import Nav from "./components/Nav";
import Person from "./components/Person";
import "./App.css";
import { formatDate, mmddyyFormat } from "./utils";
import axios from "axios";

function App() {
  const [currentDate, setCurrentDate] = useState("");
  const [ugyelet, setUgyelet] = useState({});
  const [currentUgyelet, setCurrentUgyelet] = useState({});
  const [curretUgyeletOk, setCurrentUgyeletOK] = useState(true);
  const [isToday, setIsToday] = useState(true);
  const version = "1.0.9";

  useEffect(() => {
    setCurrentDate(formatDate(Date()));
    downloadJSON();
  }, []);

  const downloadJSON = async () => {
    try {
      const response = await axios.get("/ugyelet.json");
      const data = response.data;
      setUgyelet(data);

      const date = new Date(Date());
      const ugyeletDateFormat = mmddyyFormat(date);

      if (data.hasOwnProperty(ugyeletDateFormat)) {
        setCurrentUgyelet(data[ugyeletDateFormat]);
        console.log(data[ugyeletDateFormat]);
        setCurrentUgyeletOK(true);
      } else {
        setCurrentUgyeletOK(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeCurrentUgyelet = (date) => {
    const ugyeletDateFormat = mmddyyFormat(date);

    if (ugyeletDateFormat === mmddyyFormat(new Date())) {
      setIsToday(true);
    } else {
      setIsToday(false);
    }

    if (ugyelet.hasOwnProperty(ugyeletDateFormat)) {
      setCurrentUgyelet(ugyelet[ugyeletDateFormat]);
      setCurrentUgyeletOK(true);
    } else {
      setCurrentUgyeletOK(false);
    }
  };

  const onDateChange = (e) => {
    setCurrentDate(e.target.value);
    changeCurrentUgyelet(e.target.value);
  };

  const onTodayClick = (e) => {
    setCurrentDate(formatDate(Date()));
    changeCurrentUgyelet(Date());
  };

  const onYesterdayClick = (e) => {
    const dateHolder = new Date(currentDate);
    dateHolder.setDate(dateHolder.getDate() - 1);
    setCurrentDate(formatDate(dateHolder));
    changeCurrentUgyelet(dateHolder);
  };

  const onTomorrowClick = (e) => {
    const dateHolder = new Date(currentDate);
    dateHolder.setDate(dateHolder.getDate() + 1);
    setCurrentDate(formatDate(dateHolder));
    changeCurrentUgyelet(dateHolder);
  };

  return (
    <Fragment>
      <Nav version={version} />

      <div className="mt-4 pl-3 md:pl-auto container mx-auto">
        <p>
          Adatok frissítve: {ugyelet.lastUpdated}
          <button
            onClick={() => window.location.reload(false)}
            className="ml-3 pl-3 pr-3 rounded border-2 border-primary focus:secondary focus:outline-none hover:bg-orange-100 font-semibold"
          >
            <FontAwesomeIcon icon={faSync} />
          </button>
        </p>
      </div>

      <div className="pl-3 mt-5 md:pl-auto container mx-auto">
        <div className="mr-2 md:mr-auto flex flex-row flex-wrap">
          <input
            type="date"
            className="rounded border-2 border-primary focus:secondary focus:outline-none font-semibold max-h-11 flex-grow md:flex-grow-0"
            value={currentDate}
            onChange={onDateChange}
          />

          <div className="flex flex-row md:mt-0 mt-3 w-screen md:w-auto">
            <button
              className="flex-none rounded border-2 border-primary focus:secondary focus:outline-none hover:bg-orange-100 pt-2 pl-4 pb-2 pr-4 ml-0 mr-3 md:ml-3"
              onClick={onYesterdayClick}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowLeft} />{" "}
            </button>
            <button
              className={`flex-grow rounded border-2 border-primary pt-2 pl-4 pb-2 pr-4 mr-3 focus:secondary focus:outline-none
                ${isToday
                  ? "bg-primary text-white hover:bg-secondary"
                  : "hover:bg-orange-100"
                }
              `}
              onClick={onTodayClick}
            >
              <FontAwesomeIcon icon={faHome} /> Ma
            </button>
            <button
              className="flex-none rounded border-2 border-primary focus:secondary focus:outline-none hover:bg-orange-100 pt-2 pl-4 pb-2 pr-4"
              onClick={onTomorrowClick}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowRight} />{" "}
            </button>
          </div>
        </div>

        <div className="data mt-3 mb-5">
          {curretUgyeletOk ? (
            <Fragment>
              <Person name={currentUgyelet.ip} title="IP" />

              <Person name={currentUgyelet.hd} title="HD" />

              <Person name={currentUgyelet.hmszcsml1} next={currentUgyelet.hmszcsml2} title="HM Szentes-Csongrád-Mindszent" />

              <Person name={currentUgyelet.hmkkmtl1} next={currentUgyelet.hmkkmtl2} title="HM Kecskemét" />

              <Person name={currentUgyelet.hmfkdml1} next={currentUgyelet.hmfkdml2} title="HM Szabadszállás" />

              <Person name={currentUgyelet.optika} title="Optika" />
            </Fragment>
          ) : (
            <div>A keresett dátumon nem található adat!</div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default App;
